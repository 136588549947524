import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import useLockBodyScroll from "../../custom/use-lock-body-scroll"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"
import Newsletter from "./newsletter"

import face from "../../images/toolbar/facebook.svg"
import insta from "../../images/toolbar/instagram.svg"
import tube from "../../images/toolbar/youtube.svg"
import uber from "../../images/toolbar/uber.svg"
import logo from "../../images/logo_branco.svg"
import livro from "../../images/icon_livro_reclamacoes.png"
import selo from "../../images/selo-white.png"
import PremiumDent from "../../images/toolbar/CAR-FooterPremiumDent.png"
import Grupo from "../../images/toolbar/premium-grupo.png"


import { handleClick } from "../../custom/gtag-click"

const Footer = ({ data, spaceTop, ...props }) => {
  const [modal, setModal] = useState(false)
  useLockBodyScroll(modal)

  return (
    <StyledFooter>
      <div className={spaceTop === true ? "footer space-top" : "footer"}>
        <div className="footerTop">
          <p className={font.rB + " footerTopTitle"}>
            {props.hasAppointmentButton
              ? "MARQUE JÁ A SUA CONSULTA"
              : data.newsletter.subscreva}
          </p>
          {props.hasAppointmentButton ? (
            <Link
              className={`${font.rSB} footerSubscreverBtn`}
              to="/pedido-de-marcacao"
            >
              <span>Marcar consulta</span>
            </Link>
          ) : (
            <Button
              className={font.rSB + " footerSubscreverBtn"}
              onClick={() => {
                setModal(!modal)
              }}
            >
              {data.newsletter.btnTxt}
            </Button>
          )}
        </div>
        <CSSTransition
          in={modal === true}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <Newsletter setModal={setModal} />
        </CSSTransition>

        <div className="footerBottom">
          <div className="footerContent">
            <div className="footerSegment fS1">
              <Link className="logo" to="/">
                <img className="logoCarreira" src={logo} alt="Logo Carreira Dental Clinic" />
              </Link>
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                <p className={font.rB + " fSTitle"}>GRUPO:</p>
                <a href="https://www.premiumdent.pt/" target="_blank">
                  <img src={PremiumDent} alt="PremiumDent" className="PremiumDent" />
                </a>
              </div>
            </div>
            <div className="footerSegment fSLast">
              <div className={font.rSB + " fSBottom fS4"}>
                <a
                  href="https://www.facebook.com/carreiradentalclinic"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={face} alt="Logo do Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/carreiradentalclinic/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={insta} alt="Logo do Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEtWAWV_Vvhu7jvuDQ081sQ"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={tube} alt="Logo do Youtube" />
                </a>
              </div>
              <div
                className={font.rSB + " fSBottom fS5 uber"}
                style={{ marginTop: "15px" }}
              >
                <div className={`${font.rSB} uber-speech-bubble`}>
                  Tem consulta marcada? Nós chamamos-lhe um Uber!
                </div>
                <div>
                  <img src={uber} alt="Logo da Uber" />
                </div>
              </div>


            </div>
            <div className="footerSegment fS2">
              <p className={font.rB + " fSTitle"}>{data.agueda.title}</p>
              <p className={font.rSB + " fSBottom"} >
                <a onClick={() => handleClick(`tel:+351${data.agueda.contacto[0]}`, 'Águeda')}>
                  {data.agueda.contacto[0]}<small style={{ color: "darkgray", fontSize: "15px", lineHeight: "2px" }}>·¹</small><br></br>
                </a>
                &nbsp;<span style={{ color: "#b7a99a" }}>&#9646;</span>&nbsp;
                <a onClick={() => handleClick(`tel:+351${data.agueda.contacto[1]}`, 'Águeda')}>
                  {data.agueda.contacto[1]}<small style={{ color: "darkgray", fontSize: "15px" }}>·²</small>
                </a>
              </p>
              <p style={{ marginTop: "0.5vw", fontSize: "12px", color: "#fff" }} className={font.rSB}>ERS - 14858/2017</p>

            </div>
            <div className="footerSegment fS2">
              <p className={font.rB + " fSTitle"}>{data.aveiro.title}</p>
              <p className={font.rSB + " fSBottom"}>
                <a onClick={() => handleClick(`tel:+351${data.aveiro.contacto[0]}`, 'Aveiro')}>
                  {data.aveiro.contacto[0]}<small style={{ color: "darkgray", fontSize: "15px", lineHeight: "2px" }}>·¹</small><br></br>
                </a>
                &nbsp;<span style={{ color: "#b7a99a" }}>&#9646;</span>&nbsp;
                <a onClick={() => handleClick(`tel:+351${data.aveiro.contacto[1]}`, 'Aveiro')}>
                  {data.aveiro.contacto[1]}<small style={{ color: "darkgray", fontSize: "15px" }}>·²</small>
                </a>
              </p>
              <p style={{ marginTop: "0.5vw", fontSize: "12px", color: "#fff" }} className={font.rSB}>ERS - 11102/2015</p>

            </div>
            <div className="footerSegment fS2">
              <p className={font.rB + " fSTitle"}>{data.oliveira.title}</p>
              <p
                className={font.rSB + " fSBottom"}
                style={{ justifyContent: "center" }}
              >
                <a onClick={() => handleClick(`tel:+351${data.oliveira.contacto[0]}`, 'Oliveira de Azeméis')}>
                  <p>{data.oliveira.contacto[0]}<small style={{ color: "darkgray", fontSize: "15px", lineHeight: "2px" }}>·¹</small><br></br>
                  </p>
                </a>
                &nbsp;<span style={{ color: "#b7a99a" }}>&#9646;</span>&nbsp;
                <a onClick={() => handleClick(`tel:+351${data.oliveira.contacto[1]}`, 'Oliveira de Azeméis')}>
                  {data.oliveira.contacto[1]}<small style={{ color: "darkgray", fontSize: "15px" }}>·²</small>
                </a>
              </p>
              <p style={{ marginTop: "0.5vw", fontSize: "12px", color: "#fff" }} className={font.rSB}>ERS - 23663/2023</p>

            </div>
            <div className="footerSegment fS2">
              <p className={font.rB + " fSTitle"}>{data.porto.title}</p>
              <p className={font.rSB + " fSBottom"} >
                <a onClick={() => handleClick(`tel:+351${data.porto.contacto[0]}`, 'Porto')}>
                  {data.porto.contacto[0]}<small style={{ color: "darkgray", fontSize: "15px", lineHeight: "2px" }}>·¹</small><br></br>
                </a>
                &nbsp;<span style={{ color: "#b7a99a" }}>&#9646;</span>&nbsp;
                <a onClick={() => handleClick(`tel:+351${data.porto.contacto[1]}`, 'Porto')}>
                  {data.porto.contacto[1]}<small style={{ color: "darkgray", fontSize: "15px" }}>·²</small>
                </a>
              </p>
              <p style={{ marginTop: "0.5vw", fontSize: "12px", color: "#fff" }} className={font.rSB}>ERS - 19811/2020</p>

            </div>

            <div className="footerSegment fS2">
              <p className={font.rB + " fSTitle"}>{data.viseu.title}</p>
              <p className={font.rSB + " fSBottom"}  >
                <a onClick={() => handleClick(`tel:+351${data.viseu.contacto[0]}`, 'Viseu')}>
                  {data.viseu.contacto[0]}<small style={{ color: "darkgray", fontSize: "15px", lineHeight: "2px" }}>·¹</small><br></br>
                </a>
                &nbsp;<span style={{ color: "#b7a99a" }}>&#9646;</span>&nbsp;
                <a onClick={() => handleClick(`tel:+351${data.viseu.contacto[1]}`, 'Viseu')}>
                  {data.viseu.contacto[1]}<small style={{ color: "darkgray", fontSize: "15px" }}>·²</small>
                </a>
              </p>
              <p style={{ marginTop: "0.5vw", fontSize: "12px", color: "#fff" }} className={font.rSB}>ERS - 20402/2021</p>

              <small style={{ color: "darkgray" }}>·¹«Chamada para a rede móvel nacional»</small><br></br>
              <small style={{ color: "darkgray" }}>·²«Chamada para a rede fixa nacional»</small>
            </div>
            <div className="footerSignature">
              <div className={font.rSB}>
                <a
                  href="/termos_e_condicoes"
                  rel="noreferrer noopener"
                  style={{ display: "inline-block", marginBottom: "15px" }}
                >
                  Termos e Condições
                </a>
              </div>
              <div className={font.rSB}>
                <a
                  href="/politica_de_privacidade"
                  rel="noreferrer noopener"
                  style={{ display: "inline-block", marginBottom: "15px" }}
                >
                  Política de Privacidade
                </a>
              </div>
              <div className={font.rSB}>
                Created: Invisual.pt
              </div>
              <div className={font.rSB}>
                {new Date().getFullYear()} &#169; Carreira Dental Clinic
              </div>
              <div className="livro">
                <a
                  href="https://www.livroreclamacoes.pt/inicio"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={livro} alt="Logo do Livro de Reclamações" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.div`
a{
  cursor:pointer;
}
  .footerTop {
    text-align: center;
  }
  .footerTopTitle {
    font-size: calc(19px + 7 * (100vw - 320px) / 448);
    line-height: 1.3em;
    letter-spacing: 0.15em;
    padding: 0 30px;
  }
  .footerSubscreverBtn {
    width: fit-content;
    padding: 10px 20px;
    margin: auto;
    border: solid;
    font-size: calc(12px + 7 * (100vw - 320px) / 448);
    letter-spacing: 0.1em;
    color: #b7a99a;
    margin-top: 40px;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;

    transition: all 0.2s;
  }
  .footerSubscreverBtn:hover {
    background-color: #b7a99a;
    color: #ffffff;
    border-color: #b7a99a;
    letter-spacing: 0.25em;
  }
  .footerBottom {
    position: relative;
    margin-top: 40px;
    background-color: #000;
    padding-bottom: 30px;
  }
  .footerContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fSTitle {
    color: #b7a99a;
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .PremiumDent {
    width: 60%;
  }
  .fSBottom {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 1.8px;
    display: flex;
  }
  .fS1 {
    min-width: 250px;
    margin: 30px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fS1{
    .logo{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logoCarreira{
      width: 90%;
    }
    .selo{
      width: 30%;
      margin-bottom: 5%;
    }
  }
  .fS2 {
    text-align: center;
    margin: 20px 0;
  }
  .fS4,
  .fS5 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .fS4 img {
    margin: 0 15px;
    max-height: 2.25em;
  }
  .fS5 img {
    margin: 20px 15px;
    max-height: 1.2em;
  }

  // Foi-me pedido para fazer um hover no Uber em vez de linkar para o site
  // - Sérgio
  .uber {
    position: relative;

    &:hover .uber-speech-bubble {
      opacity: 1;
    }
  }

  .uber-speech-bubble {
    position: absolute;
    background: #b7a99a;
    border-radius: 0.4em;
    border-color: #b7a99a;
    width: 80vw;
    padding: 25px;
    top: -200%;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  .uber-speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 22px solid transparent;
    border-top-color: #b7a99a;
    border-bottom: 0;
    margin-left: -22px;
    margin-bottom: -22px;
  }

  .footerSegment .logoCarreira {
    color: #ffffff;
    font-size: 3.7em;
    font-weight: 600;
  }
  .footerSegment .logoDental {
    color: #ffffff;
    font-size: 1.5em;
  }
  .footerSignature {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
  .livro {
    max-width: 80px;
    margin: 2em auto 0;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      display: inline-block;
    }
  }
  .space-top{
    margin-top: 140% !important;
  }
`
