import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(lock) {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;  
    if(lock===true){
      // Get original value of body overflow
      // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
      // Re-enable scrolling when component unmounts
    }
    else{
      document.body.style.overflow = originalStyle;
    }
    return () => document.body.style.overflow = originalStyle;
  }, [lock]); // Empty array ensures effect is only run on mount and unmount
}