import styled, { css } from "styled-components"

export const Button = styled.button`
  background: transparent;
  user-select: none;
  outline: none;
  cursor: pointer;

  ${props => props.styleless && css`
    background: unset;
    border-radius: unset;
    border: unset;
  `}

  ${props => props.regular && css`
    color: #ffffff;
    background-color: #b7a99a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    font-size: calc(12px + 4 * (100vw - 768px) / 1152);
    letter-spacing: 0.1em;
  `}

  ${props => props.asLink && css`
    display: inline-block;
    border: none;
    text-align: left;
  `}
`
