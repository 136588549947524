import React, { useState, useRef, useEffect } from "react"
import useEventListener from "../../custom/use-event-listener"
import { Link } from "gatsby"

import styled, { css } from "styled-components"
import font from "../../fonts/fonts.module.scss"

import logoB from "../../images/logo_preto.svg"
import logoW from "../../images/logo_branco.svg"

import seloB from "../../images/selo-black.png"
import seloW from "../../images/selo-white.png"
import BtnOpenDayInvisalign from "../../images/openday/opendayinvisalign.svg"

const Navbar = ({ color, ...props }) => {
  const navbar = useRef(null)
  const [prevScrollPos, setPrevScrollPos] = useState("")
  const [currentScrollPos, setCurrentScrollPos] = useState("")
  const [screenVH, setScreenVH] = useState("")
  const [isHome, setIsHome] = useState(color === "white" ? true : false)

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset)
    setScreenVH(window.innerHeight)
  }, [])
  useEventListener("scroll", () => scroller())

  function scroller() {
    setCurrentScrollPos(window.pageYOffset)
    if (prevScrollPos > currentScrollPos || window.pageYOffset <= 0) {
      navbar.current.style.top = "0px"
      if (currentScrollPos > screenVH && color === "white") {
        setIsHome(false)
      } else if (color === "white") {
        setIsHome(true)
      }
    } else {
      navbar.current.style.top = "-200px"
    }
    setPrevScrollPos(currentScrollPos)
  }

  return (
    <StyledNav isHome={isHome} ref={navbar}>
      <div className="logonav">
        <Link className="logo" to="/">
          <img
            src={isHome ? logoW : logoB}
            alt="Logo"
            className="logoCarreira"
            id="tbImg"
          />
          <img
            src={isHome ? seloW : seloB}
            alt="Selo de 20 anos"
            className="selo"
            id="seloImg"
          />
        </Link>
      </div>
      <div className="links">
        <Link
          to="/sobre"
          className={font.rSB + " link"}
          activeClassName="active"
        >
          SOBRE
        </Link>
        <div className="services">
          <Link
            to="/servicos"
            className={font.rSB + " link services-link"}
            activeClassName="active"
            partiallyActive={true}
          >
            SERVIÇOS
          </Link>
          <div className="sub-menu">
            <Link
              to="/servicos/implantologia_av"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              IMPLANTOLOGIA
            </Link>
            <Link
              to="/servicos/cirurgia_oral"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              CIRURGIA ORAL
            </Link>
            <Link
              to="/servicos/ortodontia"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              ORTODONTIA
            </Link>
            <Link
              to="/servicos/reabilitacao_estetica"
              className={font.rSB + " link sub-link"}
              activeClassName="active-reab"
            >
              REABILITAÇÃO ESTÉTICA
            </Link>
            <Link
              to="/servicos/periodontologia"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              PERIODONTOLOGIA
            </Link>
            <Link
              to="/servicos/prostodontia"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              PROSTODONTIA
            </Link>
            <Link
              to="/servicos/dentisteria"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              DENTISTERIA
            </Link>
            <Link
              to="/servicos/endodontia"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              ENDODONTIA
            </Link>
            <Link
              to="/servicos/odontopediatria"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              ODONTOPEDIATRIA
            </Link>
            <Link
              to="/servicos/higiene_oral"
              className={font.rSB + " link sub-link"}
              activeClassName="active"
            >
              HIGIENE ORAL
            </Link>
            <Link
              to="/servicos/oclusao"
              className={font.rSB + " link sub-link"}
              activeClassName="active-reab"
            >
              OCLUSÃO
            </Link>
            <Link
              to="/servicos/harmonizacao-facial"
              className={font.rSB + " link sub-link"}
              activeClassName="active-reab"
            >
              HARMONIZAÇÃO FACIAL
            </Link>
          </div>
        </div>
        <Link
          to="/sorrisos"
          className={font.rSB + " link"}
          activeClassName="active"
        >
          SORRISOS
        </Link>
        <Link
          to="/blogue"
          className={font.rSB + " link"}
          activeClassName="active"
          partiallyActive={true}
        >
          BLOGUE
        </Link>
        <Link
          to="/contactos"
          className={font.rSB + " link"}
          activeClassName="active"
        >
          CONTACTOS
        </Link>
        <Link
          to="/pedido-de-marcacao"
          className={font.rSB + " consulta"}
          activeClassName="consultaActive"
          id="consultaBtn"
        >
          MARCAR CONSULTA
        </Link>

        <Link
          to="/openday/implantologia"
          className={font.rSB + " openday"}
          activeClassName="opendayActive"
          id="opendayBtn"
        >
          MÊS DA IMPLANTOLOGIA
        </Link>
      </div>
    </StyledNav>
  )
}

export default Navbar

const StyledNav = styled.div`
  position: ${props => (props.isHome ? "absolute" : "relative")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.isHome ? "#ffffff" : "#231f20")};
  background-color: ${props => (props.isHome ? "unset" : "#fff")};
  z-index: 2;
  padding: 12px 0;
  box-shadow: ${props =>
    props.isHome ? "none" : "0 3px 6px 0 rgba(0, 0, 0, 0.16)"};
  .logonav {
    margin-left: 8%;
    justify-self: start;
  }
  .logo {
    top: 0;
    display: flex;
    align-items: center;
    padding-top: 0;
  }
  .selo {
    width: 7em;
    margin: 0 2em;
  }
  .logoCarreira {
    margin: 0;
    position: relative;
    width: 13em;
    text-align: center;
  }
  .services {
    position: relative;
  }
  .links {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-right: 8%;
    .link {
      position: relative;
      font-size: calc(10px + 3 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px) {
        font-size: 13px;
      }
      letter-spacing: 0.1em;
    }
    .active::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 3em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .sub-menu {
    visibility: hidden;
    opacity: 0;
    ${props =>
      props.isHome
        ? css`
            background-color: rgba(0, 0, 0, 0.65);
          `
        : css`
            background-color: #fff;
          `}
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.16);
    margin-top: 1em;
    padding: 0 25%;
    position: absolute;
    left: -70%;
    transform: translateY(0em);
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    .sub-link {
      padding: 0;
      margin: 25px 0;
    }
    .active::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .active-reab::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .services:focus .sub-menu,
  .services:focus-within .sub-menu,
  .services:hover .sub-menu {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }
  a {
    position: relative;
    padding-top: 1em;
    text-align: center;
    transition: all 0.2s;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .openday {
    text-align: center;
    font-size: calc(10px + 3 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 13px;
    }
    padding: 0 20px;
    height: 3em;
    line-height: 3em;
    border: ${props =>
      props.isHome ? "solid 1px #ffffff" : "solid 1px #b7a99a"};
    background-color: ${props => (props.isHome ? "#707070" : "#eeeae6")};

    :hover {
      background-color: ${props => (props.isHome ? "#707070" : "#eeeae6")};
    }
  }

  .consulta {
    text-align: center;
    font-size: calc(10px + 3 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 13px;
    }
    padding: 0px 14px;
    height: 3em;
    line-height: 3em;
    border: ${props =>
      props.isHome ? "solid 1px #ffffff" : "solid 1px #b7a99a"};
    :hover {
      background-color: ${props => (props.isHome ? "#707070" : "#eeeae6")};
    }
  }
  .consultaActive {
    background-color: #eeeae6;
    pointer-events: none;
  }
  @media only screen and (max-width: 1600px) {
    .links {
      width: 60%;
    }
  }
  @media only screen and (max-width: 1300px) {
    .consulta {
      padding: 0 30px;
    }
    .logonav {
      .logoCarreira {
        width: 15vw;
      }
      .selo {
        width: 6.5em;
      }
    }
    .links {
      width: 63%;
    }
  }
  @media only screen and (max-width: 1200px) {
    .consulta {
      padding: 0 20px;
    }
    .logonav {
      .selo {
        width: 6em;
      }
    }
    .links {
      width: 66%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .consulta {
      padding: 0 10px;
    }
    .logonav {
      .selo {
        width: 5.5em;
      }
    }
  }
`
